

const LiveBoard = ({ members }) => {
    return (
        <div>
          <div class="live-summary-title">
            <h1>Current Gameweek Summary</h1>
            <p> Green means the player has played. Red means the player has not played. Yellow means played but 0 minutes.</p>
          </div>
       
        <table className="live-summary">
          <tbody>
            <tr>
              <td className="name-cell">Team name</td>
              <td className="name-cell">Points</td>
              <td className="name-cell">Lineup</td>
              <td className="name-cell">Bench</td>
            </tr>
            {members.map((member)=>(
                <tr key={member.info.entry}>
                    <td>{member.info.entry_name}</td>
                    <td>{member.points}</td>
                    <td>
                    <div className="playerTable">
                    {member.playerLineup.map((player)=>(
                        <div className="player" key={member.info.entry+player.stats.id}>
                            <span className="player-name">{player.stats.web_name}</span>
                            {player.is_captain &&       
                             <span className="isCaptain">C</span>
                            }
                             {player.is_vice_captain &&       
                             <span className="isViceCaptain">V</span>
                            }
                            
                            <span className="player-points">{player.points}</span>
                            
                        </div>   
                    ))}
                    </div>
                    </td>
                    <td>
                    <div className="playerTable">
                    {member.playerBench.map((player)=>(
                        <div className="player" key={member.info.entry+player.stats.id}>
                            <span className="player-name">{player.stats.web_name}</span>
                            <span className="player-points">{player.points}</span>
                        </div>    
                    ))}
                    </div>  
                    </td>

                </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
}

export default LiveBoard
