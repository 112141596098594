

const Leaderboard = ({ members }) => {
    return (
        <div>
            <table className="player-leaderboard">
                <tr>
                    <td className="name-cell">Team Name</td>
                    <td className="name-cell">Player Name</td>
                    <td className="name-cell">Wins</td>
                    <td className="name-cell">Ties</td>
                    <td className="name-cell">Loses</td>
                    <td className="name-cell">Overall</td>
                    <td className="name-cell">Net Profits</td>
                    <td className="name-cell">Grand Total</td>
                </tr>
                {members.map((member)=>(
                    <tr key={member.info.entry}>
                        <td>{member.info.entry_name}</td>
                        <td>{member.info.player_name}</td>
                        <td>{member.standingEntry.win}</td>
                        <td>{member.standingEntry.ties}</td>
                        <td>{member.standingEntry.loss}</td>
                        <td>{member.info.total}</td>
                        <td>${member.standingEntry.winnings}</td>
                        <td>${member.standingEntry.winningsTotal}</td>
                    </tr>
                ))}

            </table>
        </div>

    )
}

export default Leaderboard
