const Header = ({title}) => {
    document.title = title;
    return (
        <header>
            <h1>{title}</h1>
        </header>
    )
}

Header.defaultProps = {
    title: 'Loading...'
}

export default Header
