import {useState, useEffect} from 'react'
import Header from './components/Header'
import Leaderboard from './components/Leaderboard'
import LiveBoard from './components/LiveBoard'
import './css/index.css';

const App = () =>{
  const [title, setTitle] = useState();
  const [table, setTable] = useState([]);
  const [liveboard, setLiveBoard] = useState([]);

useEffect(() => {
  const getTitle = async () => {
    const title = await fetchResults('/.netlify/functions/fpl/league-title');
    setTitle(title)
  }

  const getTable = async () => {
    const table = await fetchResults('/.netlify/functions/fpl/league-table');
    setTable(table)
  }

  const getLiveBoard = async () => {
    const table = await fetchResults('/.netlify/functions/fpl/live-board');
    setLiveBoard(table)
  }

  getTitle()
  getTable()
  getLiveBoard()

}, [])

const fetchResults = async (link) => {
  const res = await fetch(link);
  const data = await res.json()
  return data;
}



  return (
    <div className="App">
      <Header title={title}/>
      <Leaderboard members={table}/>
      <LiveBoard members={liveboard}/>
    </div>
  );
}

export default App;
